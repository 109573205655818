import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Footer } from "./components/footer";
import { Loader } from "./components/loader";
import { NavBar } from "./components/nav-bar";
import { ProtectedRoute } from "./components/protected-route";
import { UserSearchPage } from "./pages/user-search-page";
import { Home } from "./pages/home";
import { NotFound } from "./pages/not-found";
import { UserView } from "./pages/user-view";
import { CompanyView } from "./pages/company-page/company-view";
import './styles/general-styles.scss';
import { Dashboard } from "./pages/dashboard";
import { BulkUploadPage } from "./pages/bulk-upload/BulkUploadPage";
import { CompanyListPage } from "./pages/company-list/CompanyListPage";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((at: string) => {
        localStorage.setItem('token', at);
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-layout">
      <NavBar />
      <div className="page-layout__content">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/dashboard" exact component={Dashboard} />
          <ProtectedRoute path="/users" component={UserSearchPage} />
          <ProtectedRoute path="/user/:user" component={UserView} />
          <ProtectedRoute path="/company/:companyId/bulk-upload" component={BulkUploadPage} />
          <ProtectedRoute path="/company/:companyId" component={CompanyView} />
          <ProtectedRoute path="/companies" component={CompanyListPage} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};
