import React, { useEffect, useMemo, useState } from 'react';
import '../styles/components/_text-input.scss';

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export function TextView(props: Props) {
  const defaultValue = props.value;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const classNames = useMemo(() => {
    let retVal = 'searchField ';
    retVal += `searchField--${props.size ?? Size.Medium} `;
    if (props.fullWidth) retVal += `searchField--fullWidth `;
    if (props.fullWidth) retVal += `searchField--disabled `;
    return retVal.trim();
  }, [props]);

  const onChange = (e: any) => {
    props.onChange(e.target.value);
    setValue(e.target.value);
  }

  const onBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e.target.value);
    }
  }

  return (
    <div className={"container__textView"} style={props.wrapperStyle || {}}>
      {props.label && <p className={"body__xsmall body--bold"}>{props.label}</p>}
      <input type="text"
             placeholder={props.placeholder ?? ''}
             className={classNames}
             style={{width: '100%'}}
             disabled={props.disabled}
             value={value}
             onChange={onChange}
             onBlur={onBlur} />
    </div>
  );
}

interface Props {
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange: (val: string) => any;
  size?: Size;
  label?: string;
  value?: any;
  onBlur?: (val: any) => any;
  wrapperStyle?: any;
}
