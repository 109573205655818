import React, { useEffect, useState } from 'react';
import '../styles/components/_switch.scss';

export function Switch({value, label, switchClass, disabled, style = {}, onChange = () => {}}: Props) {
  const [enabled, setEnabled] = useState(value);
  useEffect(() => setEnabled(value), [value]);
  const changed = () => {
    if (disabled) return;
    onChange(!enabled);
    setEnabled(!enabled);
  }
  return (
    <div className={`noSelect switch row ${switchClass ?? ''}`} style={style}>
      {label && <p className="body__regular noSelect switch__label">{label}</p>}
      <label className="noSelect switch__control">
        <input className="noSelect" type="checkbox" checked={enabled} onChange={(e) => changed()} />
        <span className="noSelect switch__slider switch__slider--round" />
      </label>
    </div>
  );
}

interface Props {
  value: boolean;
  label?: string;
  switchClass?: string;
  disabled?: boolean;
  onChange?: (enabled: boolean) => void;
  style?: any
}
