import React from 'react';
import { TextView } from "../../components/TextView";
import { CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";

export function FreeUsersField(props: Props) {
  const {company} = props;
  const request = useHttpRequest('/api/company/update');
  const save = async (val: string) => {
    if (company) {
      const intValue = parseInt(val);
      request.PUT({
        key: 'FREE_USERS',
        value: intValue,
        companyId: company.id,
      })
    }
  }
  return (
    <div className={"updateableField__wrapper"}>
      <TextView label="Free users"
                disabled={request.loading}
                value={company?.freeUsers ?? '??'}
                onChange={() => {}}
                onBlur={save} />
      {request.loading && <SimpleSpinner />}
    </div>
  )
}

interface Props {
  company?: CompanyEntity;
}
