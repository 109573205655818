import React from 'react';
import { TextView } from "../../components/TextView";
import { CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";

export function CompanyNameField(props: Props) {
  const {company} = props;
  const request = useHttpRequest('/api/company/update');
  const save = async (val: string) => {
    if (company) {
      request.PUT({
        key: 'NAME',
        value: val,
        companyId: company.id,
      })
    }
  }
  return (
    <div className={"updateableField__wrapper"}>
      <TextView label="Company name"
                disabled={request.loading}
                value={company?.name ?? ''}
                onChange={() => {}}
                onBlur={save} />
      {request.loading && <SimpleSpinner />}
    </div>
  )
}

interface Props {
  company?: CompanyEntity;
}
