import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";

export const Home = () => {
  const {user} = useAuth0();

  if (!!user) {
    return <Redirect to={"/dashboard"}/>
  }

  return (<Fragment>
  </Fragment>)
}
