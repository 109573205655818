import React from 'react';
import { CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";
import { Switch } from "../../components/Switch";

export function CarParkBookingField(props: Props) {
  const {company} = props;
  const request = useHttpRequest('/api/company/update');
  const save = async (val: boolean) => {
    if (company) {
      request.PUT({
        key: 'CAR_PARKING',
        value: val,
        companyId: company.id,
      })
    }
  }
  return (
    <div>
      <div className={"updateableField__wrapper"}>
        <p className={"body__regular"} style={{marginRight: 24}}>Car parking enabled</p>
        <Switch value={company?.config?.carParkingEnabled ?? false}
                onChange={save}
                disabled={request.loading} />
        {request.loading && <SimpleSpinner />}
      </div>
    </div>

  )
}

interface Props {
  company?: CompanyEntity;
}
