import React, { useEffect } from 'react';
import { useHttpRequest } from "../../hooks/use-http-request";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export function WhereaboutsSetCounts(props: Props) {
  const whereaboutsSetCountRequest = useHttpRequest('/api/whereabouts-set-count');

  useEffect(() => {
    if (!whereaboutsSetCountRequest.loading && !whereaboutsSetCountRequest.response) {
      whereaboutsSetCountRequest.GET();
    }
  }, [whereaboutsSetCountRequest]);

  return (
    <>
      {whereaboutsSetCountRequest.loading && <p>Loading</p>}
      {!whereaboutsSetCountRequest.loading && whereaboutsSetCountRequest.response && <div className={'contentCard contentCard--fullWidth'}>
        <p className={'body__regular body--bold'}>Number of whereabouts set</p>
        <ResponsiveContainer width={'100%'} height={350}>
          <LineChart width={700} height={350} data={whereaboutsSetCountRequest.response}>
            <XAxis dataKey="date"/>
            <YAxis/>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>}
    </>
  )
}

interface Props {
}
