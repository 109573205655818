import React, { useEffect } from 'react';
import { useHttpRequest } from "../../hooks/use-http-request";

export function UserCount(props: Props) {
  const userCountRequest = useHttpRequest('/api/user-count');

  useEffect(() => {
    if (!userCountRequest.loading && !userCountRequest.response)
    userCountRequest.GET();
  }, [userCountRequest]);

  return (
    <>

      <div className={'userCount contentCard'}>
        {userCountRequest.loading ? <>
          <p>loading</p>
        </> : <>
          <p className={'body__large'}>Total users</p>
          <p className={'body__large'} style={{marginLeft: 16}}>{userCountRequest?.response?.totalUsers}</p>
        </>}
      </div>
      <div className={'userCount contentCard'}>
        {userCountRequest.loading ? <>
          <p>loading</p>
        </> : <>
          <p className={'body__large'}>Total paid users</p>
          <p className={'body__large'} style={{marginLeft: 16}}>{userCountRequest?.response?.totalPaidUsers}</p>
        </>}
      </div>
      <div className={'userCount contentCard'}>
        {userCountRequest.loading ? <>
          <p>loading</p>
        </> : <>
          <p className={'body__large'}>Total paid companies</p>
          <p className={'body__large'} style={{marginLeft: 16}}>{userCountRequest?.response?.totalPaidCompanies}</p>
        </>}
      </div>
    </>
  )
}

interface Props {
}
