import React from 'react';
import { NavLink } from 'react-router-dom';

export function UserButton(props: Props) {

  return (
    <NavLink to={`/user/${props.userId}`} exact>
      <button className="button button--primary button--compact">
        <span style={{color: 'white'}}>View user</span>
      </button>
    </NavLink>
  )
}

interface Props {
  userId: string;
}
