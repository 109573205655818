import React, { useEffect, useState } from 'react';
import { getInvitesForUser, Invite } from "../../api/user-requests";

export function InvitesList(props: Props) {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props?.userId && !loaded) {
      setLoaded(true);
      getInvitesForUser(props.userId).then(setInvites);
    }
  }, [props, loaded]);
  return (
    <>
      <h3>Invites</h3>
      <div className="inviteList__container">
        <div className="inviteList__header">
          <span className="inviteList__dateTime">Date Time</span>
          <span className="inviteList__state">Accepted?</span>
          <span className="inviteList__link">Link</span>
        </div>
        {invites.map((invite: Invite, key: number) => <div key={key} className="inviteList__inviteRow">
          <span className="inviteList__dateTime">{invite.dateTime}</span>
          <span className="inviteList__state">{invite.accepted ? 'yes' : 'no'}</span>
          <a href={`https://app.team-today.com/invite?id=${invite.id}`} className="inviteList__link">{`https://app.team-today.com/invite?id=${invite.id}`}</a>
        </div>)}
      </div>
    </>
  )
}

interface Props {
  userId?: string;
}
