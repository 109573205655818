import React, { useState } from 'react';
import { ServerEnrichedUserToCreate } from "./bulk-upload.models";
import { Button, Size, Variant } from "../../components/buttons/Button";
import { useHttpRequest } from "../../hooks/use-http-request";

export function UserRow(props: Props) {
  const {user, disabled} = props;
  const [needsCreating, setNeedsCreating] = useState(user.newUser);
  const userHttp = useHttpRequest('/api/user');

  const onCreateUserClicked = async () => {
    if (!needsCreating || !user.companyId || disabled) return;
    setNeedsCreating(false);
    try {
      await userHttp.POST(user);
    } catch (e) {
      console.error(e);
      setNeedsCreating(true);
    }
  }

  const classNames = needsCreating ? 'cell--important' : '';

  return (
    <tr className={'bulkUpload__userRow'}>
      <td className={`${classNames}`.trim()}>{needsCreating ? 'Create' : 'Exists'}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email}</td>
      <td className={!!user.teamPresentInAccount ? '' : 'cell--important'}>{user.teamName}</td>
      <td className={!!user.teamPresentInAccount ? '' : 'cell--important'}>{!!user.teamPresentInAccount ? 'Yes' : 'No (will be created)'}</td>
      <td>{user.role}</td>
      <td>{user.loginMethod}</td>
      <td>{user.defaultOffice}</td>
      <td>{!!user.defaultOffice ? (!!user.officeId ? 'Yes' : 'No (Cannot be linked)') : 'N/A'}</td>
      <td>{user.syncWithCalendar}</td>
      <td><Button variant={Variant.Outline}
                  text={"Create user"}
                  size={Size.Medium}
                  disabled={!needsCreating || disabled}
                  style={{minWidth: 150}}
                  onClick={onCreateUserClicked} /></td>
    </tr>
  )
}

interface Props {
  user: ServerEnrichedUserToCreate;
  disabled: boolean;
}
