import React from 'react';

export function SimpleSpinner(props: Props) {
  return (
    <div className="simple-spinner" style={props.style ?? {}}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

interface Props {
  style?: any;
}
