import React, { useEffect, useState } from 'react';
import { StepOneRawData } from "./StepOneRawData";
import { ServerEnrichedUserToCreate, UserToCreate } from "./bulk-upload.models";
import { StepTwoCheckingUsers } from "./StepTwoCheckingUsers";

enum Stage {
  StepOne, StepTwo
}

export function BulkUploadPage(props: Props) {
  const [currentStage, setCurrentStage] = useState(Stage.StepOne);
  const [data, setData] = useState<UserToCreate[]>([]);

  useEffect(() => {
    if (data.length === 0) setCurrentStage(Stage.StepOne)
  }, [data])

  const stepOneComplete = (data: UserToCreate[]) => {
    setData(data);
    setCurrentStage(Stage.StepTwo);
  }
  const stepTwoComplete = (data: ServerEnrichedUserToCreate[]) => {
    setCurrentStage(Stage.StepTwo);
  }

  return (
    <div className={"bulkUploadPage__container"}>
      {currentStage === Stage.StepOne && <StepOneRawData onComplete={(resp: UserToCreate[]) => stepOneComplete(resp)} />}
      {currentStage === Stage.StepTwo && <StepTwoCheckingUsers onComplete={(resp: ServerEnrichedUserToCreate[]) => stepTwoComplete(resp)} data={data} />}

    </div>
  )
}

interface Props {
}
