import React, { useState } from 'react';
import { Button, Size, Variant } from "../../components/buttons/Button";
import { WithCompaniesTableData } from "./hooks/WithCompaniesTableData";
import { SortableCompanyTable } from "./components/SortableCompanyTable";

export enum CompanyViewType {
  Paid='paid', FreeTrial='free', All='all'
}

export function CompanyListPage(props: Props) {
  const [companyType, setCompanyType] = useState(CompanyViewType.Paid);
  const tableData = WithCompaniesTableData(companyType)

  return (
    <div className={"companyList"}>
      <div className={"companyList__wrapper"}>
        <Button variant={companyType === CompanyViewType.FreeTrial ? Variant.OutlineSelected : Variant.Outline}
                size={Size.Medium}
                text={'Free > 10'}
                onClick={() => setCompanyType(CompanyViewType.FreeTrial)} />
        <Button variant={companyType === CompanyViewType.Paid ? Variant.OutlineSelected : Variant.Outline}
                size={Size.Medium}
                text={'Paid'}
                onClick={() => setCompanyType(CompanyViewType.Paid)} />
        <Button variant={companyType === CompanyViewType.All ? Variant.OutlineSelected : Variant.Outline}
                size={Size.Medium}
                text={'All'}
                onClick={() => setCompanyType(CompanyViewType.All)} />
      </div>

      {tableData.loading ? <p>Loading</p> : <SortableCompanyTable data={tableData.tableData} columns={tableData.tableColumns} />}
    </div>
  )
}

interface Props {
}
