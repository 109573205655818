import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useUserApi } from "../hooks/fetch-user";
import { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { InvitesList } from "./user-page/InvitesList";
import { CompanyButton } from "./components/CompanyButton";
import { useCompanyList } from "../hooks/fetch-company-list";
import { Company } from "../models/company.model";
import { UsersEmailField } from "./user-page/UsersEmailField";


export const UserView = () => {
  const { user } = useAuth0();
  const params: any = useParams();

  const {getUser, updateUser, userResponse} = useUserApi();
  const {getCompanyList, companyListResponse} = useCompanyList();

  useEffect(() => {
    if (!userResponse) {
      getUser(params.user);
      getCompanyList();
    }
  }, [params, getUser, userResponse, getCompanyList]);

  const onCompanyIdChange = (val: any) => {
    if (userResponse) {
      updateUser({
        ...userResponse,
        companyId: val.target.value,
      });
    }
  }

  const onAuthTypeChange = (val: any) => {
    if (userResponse) {
      updateUser({
        ...userResponse,
        authType: val.target.value,
      });
    }
  }

  const onRoleTypeChange = (val: any) => {
    if (userResponse) {
      updateUser({
        ...userResponse,
        role: val.target.value,
      });
    }
  }

  if (!user) {
    return null;
  }

  return (
    <div className="content-layout">
      <h1 className="content__title">User</h1>
      <h3>User's details</h3>

      <div>
        {userResponse?.companyId && <CompanyButton companyId={userResponse.companyId} />}
      </div>

      <div className="userItemList">
        <UsersEmailField user={userResponse}/>
        <TextField label="Company Name" disabled={true} variant="outlined" value={userResponse?.companyName ?? ''} onChange={() => {}}/>
        <FormControl fullWidth>
          <InputLabel id="company-name-select-label">Company</InputLabel>
          <Select
            labelId="company-name-select-label"
            id="company-name-select"
            value={userResponse?.companyId ?? ''}
            label="Company"
            onChange={onCompanyIdChange}
          >
            {companyListResponse.map((company: Company, key) =>
              (<MenuItem value={company.id} selected={company.id === userResponse?.companyId ?? ''} key={key}><strong>{company.name}</strong> ({company.id})</MenuItem>))}
          </Select>
        </FormControl>
        <TextField label="Team Name" disabled={true} variant="outlined" value={userResponse?.teamName ?? ''} onChange={() => {}}/>
        <TextField label="Auth Type" disabled={true} variant="outlined" value={userResponse?.authType ?? 'TT'} onChange={() => {}}/>

        <FormControl fullWidth>
          <InputLabel id="auth-type-label">Auth type</InputLabel>
          <Select
            labelId="auth-type-label"
            id="auth-type"
            value={userResponse?.authType ?? 'TT'}
            label="Auth type"
            onChange={onAuthTypeChange}
          >
            <MenuItem value={'TT'} selected={userResponse?.authType === 'TT' || userResponse?.authType === null}>Team Tracker</MenuItem>
            <MenuItem value={'MS'} selected={userResponse?.authType === 'MS'}>Microsoft 365</MenuItem>
            <MenuItem value={'GG'} selected={userResponse?.authType === 'GG'}>Google</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="role-type-label">Role type</InputLabel>
          <Select
            labelId="role-type-label"
            id="role-type"
            value={userResponse?.role ?? 'BASIC'}
            label="Role type"
            onChange={onRoleTypeChange}
          >
            <MenuItem value={'COMPANY_ADMIN'} selected={userResponse?.authType === 'COMPANY_ADMIN'}>Company Admin</MenuItem>
            <MenuItem value={'DEPARTMENT_ADMIN'} selected={userResponse?.authType === 'DEPARTMENT_ADMIN'}>Department Admin</MenuItem>
            <MenuItem value={'OFFICE_ADMIN'} selected={userResponse?.authType === 'OFFICE_ADMIN'}>Office Admin</MenuItem>
            <MenuItem value={'TEAM_ADMIN'} selected={userResponse?.authType === 'TEAM_ADMIN'}>Team Admin</MenuItem>
            <MenuItem value={'BASIC'} selected={userResponse?.authType === 'BASIC'}>Basic user</MenuItem>
          </Select>
        </FormControl>
      </div>

      <InvitesList userId={userResponse?.id} />

    </div>
  );
};
