import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo, useState } from "react";
import { getCompany } from "../../api/user-requests";
import { useParams } from "react-router-dom";
import { CompanyEntity } from "../../models/company.model";
import { Chip, Stack } from "@mui/material";
import { UsersInCompanyList } from "./UsersInCompanyList";
import { FreeTrialDialog } from "./FreeTrialDialog";
import { Switch } from "../../components/Switch";
import '../../styles/pages/_company-view.scss';
import { TextView } from "../../components/TextView";
import { Button, Size, Variant } from "../../components/buttons/Button";
import { useHttpRequest } from "../../hooks/use-http-request";
import { FreeUsersField } from "./FreeUsersField";
import { CompanyNameField } from "./CompanyNameField";
import { PaidUsersField } from "./PaidUsersField";
import { CarParkBookingField } from "./CarParkBookingField";
import { OfficeList } from "./OfficeList";
import { NumberOfMeetingRoomsField } from "./NumberOfMeetingRoomsField";
import { NumberOfTeamsField } from "./NumberOfTeamsField";
import { PaymentTypeField } from "./PaymentTypeField";
import { RenewalDateField } from "./RenewalDateField";


export const CompanyView = () => {
  const { user } = useAuth0();
  const params: any = useParams();
  const [company, setCompany] = useState<CompanyEntity | undefined>();
  const [isFreeTrialDialogOpen, setIsFreeTrialDialogOpen] = useState(false);
  const isPaidAccount = useMemo(() => (company?.paidForUsers ?? 0) > 0 && (company?.enabled), [company]);
  const isFreeAccount = useMemo(() => (company?.freeUsers ?? 0) > 10, [company]);
  const joinCompanyHttpRequest = useHttpRequest('/api/company/join');

  useEffect(() => {
    if (params.companyId) {
      getCompany(params.companyId).then(setCompany);
    }
  }, [params]);

  const onJoinCompanyClicked = () => {
    if (!company || !user) return;
    joinCompanyHttpRequest.POST({email: user.email, companyId: company.id});
    alert(`"${user.email}" will be added to "${company.name ?? company.id}" as a company admin`);
  }

  if (!user || !company) {
    return null;
  }

  return (
    <div className="content-layout">
      {company && <FreeTrialDialog isOpen={isFreeTrialDialogOpen}
                                   company={company}
                                   onClose={() => setIsFreeTrialDialogOpen(false)} />}

      <div className={"companyView__header"}>
        <h1 className="content__title">Company view</h1>

        <div className={'company__buttonRow'}>
          {params.companyId && <a href={`/#/company/${params.companyId}/bulk-upload`}>
              <Button variant={Variant.Outline}
                      size={Size.Medium}
                      text={'Bulk uploads'}
                      onClick={() => {}} />
            </a>}
          <Button variant={Variant.Primary}
                  size={Size.Medium}
                  disabled={joinCompanyHttpRequest.loading}
                  text={'Join company'}
                  onClick={onJoinCompanyClicked} />
        </div>
      </div>

      <div className="companyQuickInfo">
        <Stack direction="row" spacing={1}>
          {isPaidAccount && <Chip label="Paid account" color="success" />}
          {(company?.freeUsers > 10) && <Chip label="Free-Trial account" color="warning" />}
          {isFreeAccount && <Chip label="Free account" color="success" />}
        </Stack>
      </div>
      <div className="companyControls">
        {!isPaidAccount && <button className="button button--primary button--compact" onClick={() => setIsFreeTrialDialogOpen(true)} >
          Set up free trial
        </button>}
      </div>
      <div className="companyDetails">
        <CompanyNameField company={company} />

        <TextView label="Company Id"
                   disabled={true}
                   value={company?.id ?? '??'}
                   onChange={() => {}}/>

        <FreeUsersField company={company} />

        <PaidUsersField company={company} />

        <NumberOfTeamsField company={company} />

        <NumberOfMeetingRoomsField company={company} />

        <CarParkBookingField company={company} />

        <div className={"container__domainName"}>
          <p className={'body__xsmall body--bold'}>Domain based signup</p>
          <div className={"domainName__controls"}>
            <Switch value={company.domainNameSignupsEnabled && !company.domainNameSignupPending}
                    disabled={true} />
            <TextView placeholder={"Domain name"}
                      value={company?.domainName}
                      disabled={true}
                      onChange={val => val} />
          </div>
        </div>

        <div className={"company__adminSettings"}>
          <h3>Admin Account Settings</h3>
          <PaymentTypeField company={company} />
          <RenewalDateField company={company} />
        </div>

      </div>

      {company && <>
        <OfficeList companyId={company.id} />
        <UsersInCompanyList companyId={company.id} />
      </>}
    </div>
  );
};
