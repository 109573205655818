import React, { useEffect, useMemo, useState } from 'react';
import { ServerEnrichedUserToCreate, UserToCreate } from "./bulk-upload.models";
import { useParams } from "react-router-dom";
import { Office, Team } from "../../models/company.model";
import { useHttpRequest } from "../../hooks/use-http-request";
import { Button, Variant } from "../../components/buttons/Button";
import { User } from "../../models/user.model";
import { UserRow } from "./UserRow";


export function StepTwoCheckingUsers(props: Props) {
  const params: any = useParams();
  const companyId = useMemo(() => params.companyId, [params]);
  const teamsHttpRequest = useHttpRequest(`/api/teams?companyId=${companyId}`);
  const officesHttpRequest = useHttpRequest(`/api/offices?companyId=${companyId}`);
  const usersHttpRequest = useHttpRequest(`/api/users/${companyId}`);
  const userHttp = useHttpRequest('/api/user');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (companyId && !teamsHttpRequest.response && !teamsHttpRequest.loading) {
      teamsHttpRequest.GET();
    }
    if (companyId && !officesHttpRequest.response && !officesHttpRequest.loading) {
      officesHttpRequest.GET();
    }
    if (companyId && !usersHttpRequest.response && !usersHttpRequest.loading) {
      usersHttpRequest.GET();
    }
  }, [companyId, teamsHttpRequest, officesHttpRequest, usersHttpRequest]);

  const enrichedData = useMemo<ServerEnrichedUserToCreate[]>(() => {
    const data = props.data;
    if (teamsHttpRequest.response && officesHttpRequest.response && usersHttpRequest.response && data) {
      const teams = teamsHttpRequest.response as Team[];
      const offices = officesHttpRequest.response as Office[];
      const users = usersHttpRequest.response as User[];
      const updatedData = data.map((user: ServerEnrichedUserToCreate) => {
        const team = teams.find(t => t.name.toUpperCase() === user.teamName.toUpperCase());
        const office = offices.find(t => t.label.toUpperCase() === user.defaultOffice.toUpperCase());
        const userFromServer = users.find(t => t.email.toUpperCase() === user.email.toUpperCase());
        return {
          ...user,
          teamPresentInAccount: !!team,
          teamId: team?.id || null,
          officeId: office?.id || 0,
          newUser: !userFromServer,
          companyId: companyId
        }
      });

      return updatedData as ServerEnrichedUserToCreate[];
    }
    return []
  }, [props.data, teamsHttpRequest.response, officesHttpRequest.response, usersHttpRequest.response, companyId]);

  const onNextClick = () => {
    props.onComplete(enrichedData);
  }

  const createAllUsers = async () => {
    try {
      setLoading(true);
      await Promise.allSettled(enrichedData.map(async (user: ServerEnrichedUserToCreate) => {
        return await userHttp.POST(user);
      }));
    } finally {
      setLoading(false);
    }
  }

  if (enrichedData.length === 0) {
    return <p>No data</p>;
  }

  return (
    <div className={'bulkUpload__checkUsers'}>
      <p className={"body__large--bold"}>Check data with the server</p>
      <p className={"body__regular"}>This might take some time but it will check the data you're about to create with the server</p>
      <table>
        <tr>
          <th>New?</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th>Team name</th>
          <th>Create new team?</th>
          <th>Role</th>
          <th>Login method</th>
          <th>Default office</th>
          <th>Office exists?</th>
          <th>Sync with calendar</th>
          <th>Create</th>
        </tr>
        {enrichedData.map((row: ServerEnrichedUserToCreate, key) => <UserRow disabled={loading} user={row} key={key} />)}
      </table>
      <div className={'bulkUpload__controls'}>
        <Button variant={Variant.Primary}
                text={"Next"}
                onClick={onNextClick} />
        <Button variant={Variant.Outline}
                text={`Add all (${enrichedData.filter(ed => ed.newUser).length})`}
                onClick={createAllUsers} />
      </div>
    </div>
  )
}

interface Props {
  onComplete: (data: ServerEnrichedUserToCreate[]) => void;
  data: UserToCreate[];
}
