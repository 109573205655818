import React from 'react';
import { NavLink } from 'react-router-dom';

export function CompanyButton(props: Props) {

  return (
    <NavLink to={`/company/${props.companyId}`} exact>
      <button className="button button--primary button--compact" style={{marginBottom: 32}}>
        <span style={{color: 'white'}}>View company</span>
      </button>
    </NavLink>
  )
}

interface Props {
  companyId: string;
}
