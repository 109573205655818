import React from 'react';
import { CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export function PaymentTypeField(props: Props) {
  const {company} = props;
  const request = useHttpRequest('/api/company/update');
  const onChange = async (val: any) => {
    if (company) {
      request.PUT({
        key: 'PAYMENT_TYPE',
        value: val.target.value || null,
        companyId: company.id,
      })
    }
  }
  return (
    <div className={"company__adminSettingsRow"}>
      <p className={"body__regular"}>Payment type</p>
      <div className={"updateableField__wrapper"}>
        <FormControl fullWidth>
          <InputLabel id="payment-type-label">Payment type</InputLabel>
          <Select
            labelId="payment-type-label"
            id="payment-type"
            disabled={request.loading}
            value={company?.paymentType}
            label="Payment type"
            onChange={onChange}
          >
            <MenuItem value={undefined} selected={!company?.paymentType}></MenuItem>
            <MenuItem value={'STRIPE'} selected={company?.paymentType === 'STRIPE'}>Stripe</MenuItem>
            <MenuItem value={'MANUAL'} selected={company?.paymentType === 'MANUAL'}>Manual</MenuItem>
          </Select>
        </FormControl>
        {request.loading && <SimpleSpinner />}
      </div>
    </div>

  )
}

interface Props {
  company?: CompanyEntity;
}
