import React from 'react';
import { Button, Size, Variant } from "../../components/buttons/Button";
import { useHttpRequest } from "../../hooks/use-http-request";

export function CompanyConfigCacheControl(props: Props) {
  const clearCompanyConfigCacheHttpRequest = useHttpRequest('/api/cache/company-config/clear');

  const click = async () => {
    await clearCompanyConfigCacheHttpRequest.POST({});
  }

  return (
    <div className={'userCount contentCard'}>
      <p className={'body__large'}>Clear company config</p>
      <Button variant={Variant.Primary}
              text={"Clear"}
              size={Size.Medium}
              onClick={click}
              disabled={clearCompanyConfigCacheHttpRequest.loading} />
    </div>
  )
}

interface Props {
}
