import { AxiosRequestConfig } from "axios";
import { makeRequest } from "./request";
import { CompanyEntity } from "../models/company.model";
import { User } from "../models/user.model";

export interface Invite {
  id: string;
  email: string;
  teamId: string;
  dateTime: string;
  accepted: boolean;
}

const CONFIG: AxiosRequestConfig = {
  method: "GET",
  headers: {
    "content-type": "application/json",
  },
};

export const getInvitesForUser = async (userId: string): Promise<Invite[]> => {
  const config: AxiosRequestConfig = {
    ...CONFIG,
    url: `/api/invites/user/${userId}`,
  };
  try {
    return await makeRequest({ config, authenticated: true })
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getUsersInCompany = async (companyId: string): Promise<User[]> => {
  const config: AxiosRequestConfig = {
    ...CONFIG,
    url: `/api/company/${companyId}/users`,
  };
  try {
    return await makeRequest({ config, authenticated: true })
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCompany = async (companyId: string): Promise<CompanyEntity> => {
  const config: AxiosRequestConfig = {
    ...CONFIG,
    url: `/api/company/${companyId}`,
  };

  return await makeRequest({ config, authenticated: true })
};

export const setupFreeTrial = async(companyId: string, date: string, userCount: number) => {
  const config: AxiosRequestConfig = {
    ...CONFIG,
    method: "POST",
    url: `/api/company/free-trial`,
    data: {
      companyId: companyId,
      dateTo: date,
      userCount: userCount
    }
  };

  return await makeRequest({ config, authenticated: true })

}
