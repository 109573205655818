import { useHttpRequest } from "../../../hooks/use-http-request";
import { CompanyViewType } from "../CompanyListPage";
import { useEffect, useMemo, useRef } from "react";
import { ViewCompanyButton } from "../components/ViewCompanyButton";

export function WithCompaniesTableData(companyType: CompanyViewType) {
  const {GET, response, loading, clearResponse} = useHttpRequest(`/api/companies?type=${companyType}`);
  const clearResponseRef = useRef(clearResponse);

  useEffect(() => {
    clearResponseRef.current()
  }, [companyType])

  useEffect(() => {
    if (response) return;
    GET()
  }, [GET, response])

  const tableData = useMemo(() => {
    return response ?? [];
  }, [response]);

  const tableColumns = useMemo(() => {
    return [
      {Header: 'Company Name', accessor: 'name', isSorted: companyType === CompanyViewType.All },
      {Header: 'Free users', accessor: 'numberOfFreeUsers', isSorted: companyType === CompanyViewType.FreeTrial },
      {Header: 'Paid users', accessor: 'numberOfPaidUsers', isSorted: companyType === CompanyViewType.Paid },
      {Header: 'Active users', accessor: 'numberOfUsers', isSorted: false },
      {Header: 'Renewal date', accessor: 'renewalDate', isSorted: false },
      {
        Header: () => null,
        id: 'viewCompany',
        Cell: (props: any) => (
          <ViewCompanyButton {...props} />
        ),
      }
    ];
  }, [companyType]);

  return {
    loading,
    tableData: tableData,
    tableColumns: tableColumns,
  }
}
