import axios, { AxiosRequestConfig, AxiosResponse } from "axios";


export const makeRequest = async (options: { config: AxiosRequestConfig; authenticated?: boolean; }) => {
  if (options.authenticated) {
    const token = localStorage.getItem('token');
    options.config.headers = {
      ...options.config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  const apiServerUrl: string | undefined = process.env.REACT_APP_API_SERVER_URL;
  options.config.url = `${apiServerUrl}${options.config.url}`

  const response: AxiosResponse = await axios(options.config);
  const { data } = response;

  return data;
};
