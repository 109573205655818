import React, { useEffect, useMemo } from 'react';
import { useHttpRequest } from "../../hooks/use-http-request";
import { Office } from "../../models/company.model";
import { RootOffice } from "./RootOffice";

export function OfficeList(props: Props) {
  const {companyId} = props;
  const officesHttpRequest = useHttpRequest(`/api/offices?companyId=${companyId}`);

  useEffect(() => {
    if (!officesHttpRequest.loading && !officesHttpRequest.response) {
      officesHttpRequest.GET();
    }
  }, [officesHttpRequest]);

  const rootOffices = useMemo(() => {
    if (!officesHttpRequest.response) return [];
    return officesHttpRequest.response.filter((office: Office) => !office.parentId)
  }, [officesHttpRequest.response]);

  return (
    <div>
      {rootOffices.map((office: Office, key: number) => (<RootOffice key={key}
                                                                     rootOffice={office}
                                                                     officeList={officesHttpRequest.response} />))}
    </div>
  )
}

interface Props {
  companyId: string;
}
