import { useAuth0, User } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { useEnv } from "../context/env.context";

export const useUserApi = () => {
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [loading, setLoading] = useState(false);
  const [timerId, setTimerId] = useState<any>(undefined);
  const [apiResponse, setApiResponse] = useState<User[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const makeRequest = async (options: { config: AxiosRequestConfig; authenticated?: boolean; }) => {
    if (options.authenticated) {
      const token = await getAccessTokenSilently();
      console.log(options.config.headers)
      options.config.headers = {
        ...options.config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const response: AxiosResponse = await axios(options.config);
    const { data } = response;

    return data;
  };

  const searchForUsers = async (search: string): Promise<void> => {
    if (loading) return;

    if (timerId) {
      clearTimeout(timerId);
    }

    const t = setTimeout(() => {
      setLoading(true);
      setApiEndpoint(`GET /api/users?q=${search}`);

      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/users?q=${search}`,
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      };

      makeRequest({ config, authenticated: true })
        .then(setApiResponse)
        .finally(() => {
          setLoading(false);
          setTimerId(null);
        });

    }, 500);
    setTimerId(t);
  };


  return {
    apiEndpoint,
    apiResponse,
    searchForUsers,
  };
};
