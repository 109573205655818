import React, { useMemo } from 'react';
import { Office } from "../../models/company.model";
import { OfficeFloor } from "./OfficeFloor";

export function RootOffice(props: Props) {
  const {rootOffice, officeList} = props;

  const floors = useMemo(() => {
    return officeList.filter((o: Office) => rootOffice.id === o.parentId);
  }, [rootOffice, officeList]);

  return (<div className="rootOffice__container">
    <div className="rootOffice__header">
      <p className="body__large--bold">{rootOffice.label}</p>
    </div>
    <div className="floors__list">
      {floors.map((floor, key) => <OfficeFloor floor={floor} key={key} />)}
    </div>
  </div>
  )
}

interface Props {
  rootOffice: Office;
  officeList: Office[];
}
