import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { useEnv } from "../context/env.context";

export const useHttpRequest = (apiEndpoint: string) => {
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<any | undefined>(undefined);
  const [apiPostResponse, setApiPostResponse] = useState<any | undefined>(undefined);
  const [apiPutResponse, setApiPutResponse] = useState<any | undefined>(undefined);

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const makeRequest = async (options: { config: AxiosRequestConfig; authenticated?: boolean; }) => {
    try {
      if (options.authenticated) {
        const token = await getAccessTokenSilently();
        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response: AxiosResponse = await axios(options.config);
      const { data } = response;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return (error as Error).message;
    }
  };

  const GET = async (): Promise<void> => {
    if (loading) return;

    setLoading(true);

    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${apiEndpoint}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    await makeRequest({ config, authenticated: true })
      .then((resp) => setApiResponse(resp))
      .finally(() => setLoading(false));
  };

  const POST = async (payload: any): Promise<void> => {
    if (loading) return;

    setLoading(true);

    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${apiEndpoint}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: payload
    };

    await makeRequest({ config, authenticated: true })
      .then((resp) => setApiPostResponse(resp))
      .finally(() => setLoading(false));
  };

  const PUT = async (payload: any): Promise<void> => {
    if (loading) return;

    setLoading(true);

    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}${apiEndpoint}`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      data: payload
    };

    await makeRequest({ config, authenticated: true })
      .then((resp) => setApiPutResponse(resp))
      .finally(() => setLoading(false));
  };

  const clearResponse = () => {
    setApiResponse(undefined);
    setApiPutResponse(undefined);
    setApiPostResponse(undefined);
  }


  return {
    response: apiResponse,
    postResponse: apiPostResponse,
    putResponse: apiPutResponse,
    loading: loading,
    clearResponse,
    GET, POST, PUT,
  };
};
