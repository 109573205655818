import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { useEnv } from "../context/env.context";
import { User } from "../models/user.model";

export const useUserApi = () => {
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<User | undefined>(undefined);

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const makeRequest = async (options: { config: AxiosRequestConfig; authenticated?: boolean; }) => {
    try {
      if (options.authenticated) {
        const token = await getAccessTokenSilently();
        console.log(options.config.headers)
        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response: AxiosResponse = await axios(options.config);
      const { data } = response;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return (error as Error).message;
    }
  };

  const getUser = async (userId: string): Promise<void> => {
    if (loading) return;

    setLoading(true);
    setApiEndpoint(`GET /api/user/${userId}`);

    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/user/${userId}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    await makeRequest({ config, authenticated: true })
      .then((resp) => {
        setApiResponse(resp)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateUser = async (user: User): Promise<void> => {
    setLoading(true);

    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/user`,
      method: "PUT",
      data: user,
      headers: {
        "content-type": "application/json",
      },
    };

    await makeRequest({config, authenticated: true})
      .then(setApiResponse)
      .finally(() => setLoading(false))
  }

  return {
    apiEndpoint,
    userResponse: apiResponse,
    getUser,
    updateUser,
  };
};
