import React from 'react';
import { NavLink } from 'react-router-dom';

export function TeamButton(props: Props) {

  return (
    <NavLink to={`/team/${props.teamId}`} exact>
      <button className="button button--primary button--compact">
        <span style={{color: 'white'}}>View team</span>
      </button>
    </NavLink>
  )
}

interface Props {
  teamId: string;
}
