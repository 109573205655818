import React, { useEffect, useMemo, useState } from 'react';
import { CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import { format } from "date-fns";
import { Button, Size, Variant } from "../../components/buttons/Button";

export function RenewalDateField(props: Props) {
  const {company} = props;
  const request = useHttpRequest('/api/company/update');
  const [selected, setSelected] = useState<Date>();
  const [show, setShow] = useState(false);
  const onSelect = async (day?: Date) => {
    if (day && company) {
      request.PUT({
        key: 'RENEWAL_DATE',
        value: format(day, 'yyyy-MM-dd'),
        companyId: company.id,
      })
    }
    setSelected(day);
    setShow(false);
  }

  useEffect(() => {
    if (company && company.renewalDate) {
      setSelected(new Date(company?.renewalDate));
    }
  }, [company]);

  const selectedDateString = useMemo(() => {
    if (selected) {
      return format(selected, 'PP');
    }
    return 'No renewal date selected';
  }, [selected])

  return (
    <div className={""}>
      <p className={"body__regular"}>Renewal date</p>
      <div className={"updateableField__wrapper updateableField__wrapper--spaceBetween"}>

        {show ? <div className={"rdp__wrapper"}>
          <DayPicker
            disabled={request.loading}
            mode="single"
            selected={selected}
            onSelect={onSelect}
          />
        </div> :
          <>
            <p className={"body__regular body--bold"}>{selectedDateString}</p>
            <Button variant={Variant.Outline}
                    disabled={request.loading}
                    size={Size.Small}
                    text={"Select"}
                    onClick={() => setShow(true)} />
          </>}

        {request.loading && <SimpleSpinner />}
      </div>
    </div>

  )
}

interface Props {
  company?: CompanyEntity;
}
