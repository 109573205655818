import React, { useEffect } from 'react';
import { useHttpRequest } from "../../hooks/use-http-request";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export function UsersCreatedCounts(props: Props) {
  const usersCreatedRequest = useHttpRequest('/api/users-created-count');

  useEffect(() => {
    if (!usersCreatedRequest.loading && !usersCreatedRequest.response) {
      usersCreatedRequest.GET();
    }
  }, [usersCreatedRequest]);

  return (
    <>
      {usersCreatedRequest.loading && <p>Loading</p>}
      {!usersCreatedRequest.loading && usersCreatedRequest.response && <div className={'contentCard contentCard--fullWidth'}>
        <p className={'body__regular body--bold'}>Accumulative users created</p>
        <ResponsiveContainer width={'100%'} height={350}>
          <LineChart width={700} height={350} data={usersCreatedRequest.response}>
            <XAxis dataKey="date"/>
            <YAxis/>
            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </div>}
    </>
  )
}

interface Props {
}
