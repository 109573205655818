import React from 'react';
import { TextView } from "../../components/TextView";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";
import { User } from "../../models/user.model";

export function UsersEmailField(props: Props) {
  const {user} = props;
  const request = useHttpRequest('/api/user/update');

  const save = async (val: string) => {
    request.clearResponse();
    if (user) {
      try {
        request.PUT({
          key: 'EMAIL',
          value: val,
          userId: user.id,
        }).catch((e) => console.log('error', e))
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div>
      <div className={"updateableField__wrapper"}>
        <TextView label="Email address"
                  disabled={request.loading}
                  value={user?.email ?? ''}
                  onChange={() => {}}
                  onBlur={save} />
        {request.loading && <SimpleSpinner />}
      </div>
      {!!request?.putResponse?.error && <p className={'body--bold body--error'}>{request.putResponse.message}</p>}
    </div>
  )
}

interface Props {
  user?: User;
}
