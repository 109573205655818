import React, { useRef, useState } from 'react';
import { UserToCreate } from "./bulk-upload.models";
import { Button, Variant } from "../../components/buttons/Button";


const COL_HEADER_FIRST_NAME = 'First name';
const COL_HEADER_LAST_NAME = 'Last name';
const COL_HEADER_EMAIL = 'Email address';
const COL_HEADER_TEAM_NAME = 'Team name';
const COL_HEADER_DEFAULT_OFFICE = 'Default office';
const COL_HEADER_SYNC = 'Sync with calendar';
const COL_HEADER_ROLE = 'Role';
const COL_HEADER_LOGIN_METHOD = 'Login method';
const MS_AUTH_TYPE = 'OFFICE365';
const MS_SHORT_AUTH_TYPE = 'MS';

const VALID_ROLES = ['BASIC', 'TEAM ADMIN', 'COMPANY ADMIN', 'OFFICE ADMIN'];

function checkColumLength(arr: string[]) {
  if (arr.length !== 8) {
    throw new Error(`8 columns required ${arr.length} found`);
  }
}

function checkColumHeaders(arr: string[]) {
  if (arr.includes(COL_HEADER_FIRST_NAME) &&
      arr.includes(COL_HEADER_LAST_NAME) &&
      arr.includes(COL_HEADER_EMAIL) &&
      arr.includes(COL_HEADER_TEAM_NAME) &&
      arr.includes(COL_HEADER_DEFAULT_OFFICE) &&
      arr.includes(COL_HEADER_SYNC) &&
      arr.includes(COL_HEADER_ROLE) &&
      arr.includes(COL_HEADER_LOGIN_METHOD)) {
    return
  } else {
    throw new Error(`Missing one or more column headers. Headers should be ${COL_HEADER_FIRST_NAME}, ${COL_HEADER_LAST_NAME}, ${COL_HEADER_EMAIL}, ${COL_HEADER_TEAM_NAME}, ${COL_HEADER_DEFAULT_OFFICE}, ${COL_HEADER_SYNC}, ${COL_HEADER_ROLE}, ${COL_HEADER_LOGIN_METHOD}`);
  }
}

function parseAuthType(authType: string): string {
  if (authType.toUpperCase() === MS_AUTH_TYPE) return MS_SHORT_AUTH_TYPE;
  throw new Error('Invalid auth type: ' + authType)
}

function mapRowToObject(arr: string[]): UserToCreate {
  return {
    firstName: arr[0].trim(),
    lastName: arr[1].trim(),
    email: arr[2].trim(),
    teamName: arr[3].trim(),
    defaultOffice: arr[4].trim(),
    syncWithCalendar: arr[5].toUpperCase().trim(),
    role: arr[6].toUpperCase().trim(),
    loginMethod: parseAuthType(arr[7].toUpperCase().trim()),
  }
}

function checkUserToCreateObjects(user: UserToCreate)  {
  if (user.firstName.trim() === '') {
    throw new Error(`First name required`);
  }
  if (user.lastName.trim() === '') {
    throw new Error(`Lastname required`);
  }
  if (user.email.trim() === '') {
    throw new Error(`Email required`);
  }
  if (user.syncWithCalendar && (!['Y', 'N'].includes(user.syncWithCalendar))) {
    throw new Error(`syncWithCalendar needs to be empty, 'Y' or 'N'`);
  }
  if (user.role === '') {
    throw new Error(`Role required`);
  }
  if (!VALID_ROLES.includes(user.role)) {
    throw new Error(`Role must be one of ${VALID_ROLES} but it is ${user}`);
  }
  if (user.loginMethod === '') {
    throw new Error(`Login method required`);
  }
  if (user.loginMethod !== MS_SHORT_AUTH_TYPE) {
    throw new Error(`Login method must be Office365`);
  }
}


export function StepOneRawData(props: Props) {
  const textRef = useRef<any>();
  const [error, setError] = useState('');

  const onNextClick = async () => {
    setError('')
    const data = textRef.current.value;
    const rows = data.split('\n');
    const header = rows.shift().split('\t');
    const content = rows.map((r: string) => r.split('\t'));

    try {
      checkColumLength(header);
      checkColumHeaders(header);
      content.forEach(checkColumLength);
      const userToCreateArray = content.map(mapRowToObject);
      userToCreateArray.forEach(checkUserToCreateObjects);
      console.log(header, content);
      props.onComplete(userToCreateArray);
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    }
  }

  return (
    <div>
      <p className={"body__large--bold"}>Paste excel data</p>
      <p className={"body__regular"}>Data should be tab seperated and each user to create on a new line, the following headers on the first line and columns in this order:</p>
      <ul className={"bulkUpload__instructionList"}>
        <li><p className={"body__regular"}>First name</p></li>
        <li><p className={"body__regular"}>Last name</p></li>
        <li><p className={"body__regular"}>Email address</p></li>
        <li><p className={"body__regular"}>Team name</p></li>
        <li><p className={"body__regular"}>Default office</p></li>
        <li><p className={"body__regular"}>Sync with calendar</p></li>
        <li><p className={"body__regular"}>Role</p></li>
        <li><p className={"body__regular"}>Login method</p></li>
      </ul>
      <p className={"body__large--bold body--error"}>{error}</p>
      <div>
        <textarea className={"bulkUpload__textArea"} style={{width: 1024, height: 400}} ref={textRef} />
      </div>
      <Button variant={Variant.Primary} text={"Next"} onClick={onNextClick} />
    </div>
  )
}

interface Props {
  onComplete: (data: UserToCreate[]) => void;
}
