import React, { useEffect, useState } from 'react';
import { Switch } from "../../components/Switch";
import { TextView } from "../../components/TextView";
import { Office } from "../../models/company.model";
import { useHttpRequest } from "../../hooks/use-http-request";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { Button, Variant } from "../../components/buttons/Button";
import { useEnv } from "../../context/env.context";

function isNumber(n: any) {
  if (!n) return false;
  return new RegExp('^\\d*\\.?\\d*$').test(n);
}

async function getFloorPlan(urlPath: string) {
  if (!urlPath.startsWith('s3/')) return;
  const url = `https://team-today-floorplans.s3.eu-west-2.amazonaws.com/${encodeURI(urlPath)}`;
  const response = await fetch(url);
  return await response.json();
}

export function OfficeFloor(props: Props) {
  const {floor} = props;
  const [floorPlanUrl, setFloorPlanUrl] = useState(floor.officePlan);
  const [initialZoom, setInitialZoom] = useState('0.25');
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false);
  const officesHttpRequest = useHttpRequest(`/api/offices`);
  const updateInitialZoomRequest = useHttpRequest(`/api/offices/update-initial-zoom`);
  const { apiServerUrl } = useEnv();

  useEffect(() => {
    if (floorPlanUrl) {
      getFloorPlan(floorPlanUrl).then((floorPlanObj) => {
        setInitialZoom(floorPlanObj?.initialScale ?? 1);
      }).catch(console.error)
    }
  }, [floorPlanUrl]);

  const changeFloorPlanEnabled = (state: boolean, office: Office) => {
    officesHttpRequest.PUT({
      ...floor,
      floorPlanEnabled: state,
    })
  }

  const updateFloorPlanUrl = () => {
    officesHttpRequest.PUT({
      ...floor,
      officePlan: floorPlanUrl,
    })
  }

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  }

  const uploadFloorPlan = async () => {
    if (!selectedFile) {
      alert("Please first select a file");
      return;
    }

    setLoading(true)

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        // Replace this URL with your server-side endpoint for handling file uploads
        const response = await fetch(`${apiServerUrl}/api/office/${floor.id}/upload-floor-plan`, {
          method: "POST",
          body: formData
        });

        if (response.ok) {
          console.log("File upload is  successfully");
        } else {
          console.log("Failed to upload the file due to errors");
        }
      } catch (error) {
        console.error("Error while uploading the file:", error);
        alert("Error occurred while uploading the file");
      }
    } finally {
      setLoading(false);
    }
  }

  const updateInitialZoom = async () => {
    console.log(initialZoom, parseFloat(initialZoom), isNumber(parseFloat(initialZoom)))
    if (isNumber(parseFloat(initialZoom))) {
      try {
        await updateInitialZoomRequest.PUT({ officeId: floor.id, initialZoom: parseFloat(initialZoom)})
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div className="floor__container">
      <p className="body__regular">{floor.label}</p>
      <Switch style={{marginLeft: 'auto', marginRight: 16}}
              value={floor.floorPlanEnabled}
              disabled={officesHttpRequest.loading || loading}
              onChange={(state: boolean) => changeFloorPlanEnabled(state, floor)} />
      <TextView onChange={setFloorPlanUrl}
                disabled={officesHttpRequest.loading || loading}
                value={floorPlanUrl}
                onBlur={updateFloorPlanUrl} />

      {floorPlanUrl && floorPlanUrl.startsWith('s3/') && <>
        <TextView onChange={setInitialZoom}
                  disabled={officesHttpRequest.loading || loading}
                  value={initialZoom} wrapperStyle={{width: 60, marginLeft: 8, marginRight: 8}}
                  onBlur={updateInitialZoom} />
      </>}

      <input type="file" onChange={handleFileChange} disabled={officesHttpRequest.loading || loading} />
      <Button variant={Variant.Outline} text={"Upload"} onClick={uploadFloorPlan} disabled={officesHttpRequest.loading || loading} />
      {(officesHttpRequest.loading || loading) && <SimpleSpinner />}
    </div>
  )
}

interface Props {
    floor: Office;
}
