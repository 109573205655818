import React from 'react';
import { TextView } from "../../components/TextView";
import { CompanyEntity } from "../../models/company.model";
import { SimpleSpinner } from "../../components/SimpleSpinner";
import { useHttpRequest } from "../../hooks/use-http-request";

export function PaidUsersField(props: Props) {
  const {company} = props;
  const request = useHttpRequest('/api/company/update');
  const save = async (val: string) => {
    if (company) {
      const intValue = parseInt(val);
      request.PUT({
        key: 'PAID_USERS',
        value: intValue,
        companyId: company.id,
      })
    }
  }
  return (
    <div>
      <div className={"updateableField__wrapper"}>
        <TextView label="Paid users"
                  disabled={request.loading}
                  value={company?.paidForUsers ?? '??'}
                  onChange={() => {}}
                  onBlur={save} />
        {request.loading && <SimpleSpinner />}
      </div>
      <p className={"body__small updateableField__warning"}>*If company uses stripe this will automatically reset on the next billing cycle</p>
    </div>

  )
}

interface Props {
  company?: CompanyEntity;
}
