import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import '../styles/pages/dashboard.scss'
import { UserCount } from "./dashboard/UserCount";
import { WhereaboutsSetCounts } from "./dashboard/WhereaboutsSetCounts";
import { UsersCreatedCounts } from "./dashboard/UsersCreatedCounts";
import { CompanyConfigCacheControl } from "./dashboard/CompanyConfigCacheControl";

export const Dashboard = () => {
  const { user } = useAuth0();


  if (!user) {
    return <Redirect to={"/"} />
  }

  return (
    <div>
      <h3 className={'body__large'}>Welcome {user.name} to the Team Today admin dashboard</h3>
      <div className={'container__contentCards'}>
        <UserCount />
        <CompanyConfigCacheControl />
      </div>
      <WhereaboutsSetCounts />
      <UsersCreatedCounts />
    </div>
  );
};
