import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { useEnv } from "../context/env.context";
import { Company } from "../models/company.model";

export const useCompanyList = () => {
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyListResponse, setCompanyListResponse] = useState<Company[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const makeRequest = async (options: { config: AxiosRequestConfig; authenticated?: boolean; }) => {
    try {
      if (options.authenticated) {
        const token = await getAccessTokenSilently();
        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response: AxiosResponse = await axios(options.config);
      const { data } = response;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return (error as Error).message;
    }
  };

  const getCompanyList = async (): Promise<void> => {
    if (loading) return;

    setLoading(true);
    setApiEndpoint(`GET /api/companies/all`);

    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/companies/all?filter=label`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    makeRequest({ config, authenticated: true })
      .then((resp: Company[]) => {
        const sorted = resp.sort((a, b) => a.name < b.name ? 1 : -1);
        setCompanyListResponse(sorted);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    apiEndpoint,
    companyListResponse,
    getCompanyList,
  };
};
