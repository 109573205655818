import { useAuth0 } from "@auth0/auth0-react";
import { useUserApi } from "src/hooks/use-user-api";
import { NavLink } from "react-router-dom";
import { Button, Variant } from "../components/buttons/Button";
import { TextView } from "../components/TextView";

export const UserSearchPage = () => {
  const { user } = useAuth0();
  const { searchForUsers, apiResponse } = useUserApi();

  const search = (term: string) => {
    searchForUsers(term);
  }

  if (!user) {
    return null;
  }

  return (
    <div className="content-layout">
      <h1 className="content__title">User search</h1>
      <div className="content__body userPage">
        <div className={"container__search"}>
          <TextView onChange={val => search(val)} placeholder={"EMail address"} fullWidth={true} />

        </div>

        <div className="user__list">
          {apiResponse.map((userResult: any, key) => (
            <div key={key} className="user__row">
              <div>
                <p><strong>{userResult.email}</strong></p>
                <span>Company Name: {userResult.companyName}</span>
                <p>Team Name: {userResult.teamName}</p>
              </div>
              <div>
              </div>
              <div className="user__controls">

                <NavLink to={`/company/${userResult.companyId}`} exact>
                  <Button variant={Variant.Outline} text={"Company"} />
                </NavLink>
                <NavLink to={`/user/${userResult.id}`} exact>
                  <Button variant={Variant.Outline} text={"User"} />
                </NavLink>
              </div>
            </div>))}
        </div>

      </div>
    </div>
  );
};
