import React from 'react';
import { useSortBy, useTable } from "react-table";

export function SortableCompanyTable(props: Props) {
  const {columns, data, initialState} = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: columns,
      data: data,
      // @ts-ignore
      initialState: initialState,
    },
    useSortBy,
  );

  return (
    <div className={'sortableTable sortableTable__companyList'}>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className={'header__cell'}>
                  <p className={'body__regular body--bold'}>{column.render('Header')}</p>
                  <span className={'header__icon'}>{column.isSorted ? column.isSortedDesc ? 'V' : '^' : '<' }</span>
                </div>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}><p className={'body__regular'}>{cell.render('Cell')}</p></td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  )
}

interface Props {
  data: any;
  columns: any;
  initialState?: any;
}
